export const GAP = "gap";
export function generatePageIndexTypes(currentPage, numberOfPages, visibleTypes) {
    if (visibleTypes >= numberOfPages) {
        return Array.from(Array(numberOfPages)).map((_, index) => index + 1);
    }
    const pageArray = [];
    const minPageForGaps = Math.floor((visibleTypes + 1) / 2);
    if (currentPage > minPageForGaps) {
        pageArray.push(1);
        pageArray.push(GAP);
        if (currentPage <= numberOfPages - minPageForGaps) {
            pageArray.push(currentPage - 1);
            pageArray.push(currentPage);
            pageArray.push(currentPage + 1);
            pageArray.push(GAP);
            pageArray.push(numberOfPages);
        }
        else {
            for (let i = numberOfPages - minPageForGaps; i <= numberOfPages; ++i) {
                pageArray.push(i);
            }
        }
    }
    else {
        for (let i = 1; i <= minPageForGaps + 1; ++i) {
            pageArray.push(i);
        }
        pageArray.push(GAP);
        pageArray.push(numberOfPages);
    }
    return pageArray;
}
